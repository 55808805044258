import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import { PageLayout } from '../components/page-layout'
import { ProductPageContent } from '../components/product-page-content'
import { useProductDetail, DEFAULT_PAGE_TITLE } from '../lib'

export const PRODUCT_ROUTE = '/catalog/product/:productId'

export const ProductPage: React.FC = () => {
  const { productId = '' } = useParams<'productId'>()
  const { item } = useProductDetail(productId)
  const title = item ? `${item.name} | ${DEFAULT_PAGE_TITLE}` : DEFAULT_PAGE_TITLE

  return (
    <>
      <Helmet>
        <title>{title}</title>
        {item && <meta name="description" content={item.description} />}
      </Helmet>
      <PageLayout>{item && <ProductPageContent product={item} />}</PageLayout>
    </>
  )
}
