import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Field, FieldProps, Form, Formik } from 'formik'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import * as Yup from 'yup'

import { LOGIN_ROUTE } from '../../pages'
import { PASSWORD_RESET_REQUEST, selectPasswordResetRequest } from '../../stores/account'
import { OmChevronLeft } from '../icons'

const Schema = Yup.object().shape({
  email: Yup.string().trim().required('Email address is required').email('Invalid email address'),
})

interface IFormValues {
  email: string
}

function usePasswordResetRequest() {
  const dispatch = useDispatch()
  const state = useSelector(selectPasswordResetRequest)

  const onSubmit = useCallback(
    (email: string) => {
      dispatch(PASSWORD_RESET_REQUEST.request(email))
    },
    [dispatch],
  )

  return {
    ...state,
    onSubmit,
  }
}

export function PasswordResetRequestForm() {
  const { isFetching, error, onSubmit } = usePasswordResetRequest()

  const initialValues: IFormValues = {
    email: '',
  }

  const handleSubmit = ({ email }: IFormValues) => {
    if (!isFetching) {
      onSubmit(email)
    }
  }

  return (
    <Box>
      <Text mb={8} fontWeight="light">
        Enter your email address below and we’ll send a verification code which will allow you to
        set a new password.
      </Text>
      {error && (
        <Box borderWidth={1} borderColor="red.500" borderRadius="sm" p={4} mb={4}>
          <Text color="red.500" fontWeight="bold">
            {error}
          </Text>
        </Box>
      )}
      <Formik
        initialValues={initialValues}
        validateOnMount={true}
        validationSchema={Schema}
        onSubmit={handleSubmit}>
        {(props) => (
          <Form>
            <Stack spacing={4}>
              <Box>
                <Field name="email">
                  {({ field, form }: FieldProps) => {
                    const { name } = field
                    const { touched, errors } = form
                    const errorMessage = touched[name] ? errors[name] : ''
                    return (
                      <FormControl isInvalid={!!errorMessage}>
                        <FormLabel htmlFor={name}>Email address</FormLabel>
                        <InputGroup>
                          <Input
                            {...field}
                            id={name}
                            placeholder="Email address"
                            autoFocus={true}
                          />
                        </InputGroup>
                        <FormErrorMessage>{`${errorMessage}`}</FormErrorMessage>
                      </FormControl>
                    )
                  }}
                </Field>
              </Box>

              <Box>
                <Button type="submit" w="100%" isLoading={isFetching} isDisabled={!props.isValid}>
                  Next
                </Button>
              </Box>
              <Box>
                <Button
                  variant="link"
                  colorScheme="green"
                  as={RouterLink}
                  to={LOGIN_ROUTE}
                  leftIcon={<OmChevronLeft />}
                  iconSpacing={0}
                  onMouseDown={(e) => e.preventDefault()} // use preventDefault on mousedown to avoid form revalidation on blur
                >
                  Back to login
                </Button>
              </Box>
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  )
}
