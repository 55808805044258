import {
  Box,
  BoxProps,
  Button,
  chakra,
  forwardRef,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  Tooltip,
  useBreakpointValue,
} from '@chakra-ui/react'
import { useCallback, useMemo } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { TBasketWithAddressAndItems, TOrderWithAddressAndItems } from '../api'
import { basketPath, orderPath, useOrderOrBasketDetail, useSelectedBasket } from '../lib'
import { BasketFormModalButton } from './basket-form'
import { ConfirmDialog, IUseConfirmDialogProps, useConfirmDialog } from './confirm-dialog'
import { OmDotsVertical } from './icons'

export interface IOrderBoxProps extends BoxProps {
  order: TBasketWithAddressAndItems | TOrderWithAddressAndItems
}

export const DefinitionList = chakra('dl', {
  baseStyle: {
    '& > dt:not(style) ~ dt:not(style)': {
      mt: 4,
    },
  },
})

export const DefinitionTerm = chakra('dt', {
  baseStyle: {
    fontWeight: 'light',
  },
})

export const Definition = chakra('dd', {
  baseStyle: {
    fontWeight: 'bold',
  },
})

export const OrderBox = forwardRef<IOrderBoxProps, 'button'>((props, ref) => {
  const { basket: selectedBasket } = useSelectedBasket()
  const { order, ...rest } = props

  const isSelected = order === selectedBasket

  const displaySize = useBreakpointValue({
    base: 'base',
    sm: 'sm',
    md: 'md',
  })
  const isMobile = displaySize !== 'md'

  const {
    isBasket,
    basketErrorMessage,
    orderMessage,
    showReciprocalLink,
    canEdit,
    canSubmit,
    canCancel,
    addressDisplay,
    deliveryDateDisplay,
    cutoffDisplay,
    href,
    name,
    showCutoff,
    onCancel,
    onConfirm,
    onSelect,
    handleReorderSuccess,
    isDeleting,
    isOpeningForEdit,
    handleSelectExisting,
  } = useOrderOrBasketDetail(order)

  const cancelDialogProps: IUseConfirmDialogProps = isBasket
    ? {
        type: 'remove-basket',
        basketName: name,
        onConfirm: onCancel,
      }
    : {
        type: 'cancel-order',
        orderName: name,
        onConfirm: onCancel,
      }

  const cancelDialog = useConfirmDialog(cancelDialogProps)

  const confirmDialog = useConfirmDialog({
    type: 'confirm-basket',
    basketName: name,
    onConfirm,
  })

  const handleCancel = useCallback(() => {
    cancelDialog.onOpen()
  }, [cancelDialog])

  const handleConfirm = useCallback(() => {
    confirmDialog.onOpen()
  }, [confirmDialog])

  const mediumUpConfirmButton = useMemo(() => {
    if (!isBasket) return null
    const btn = (
      <Button
        ml={[3, 4]}
        display={['none', 'inline-flex']}
        isDisabled={!canSubmit}
        onClick={handleConfirm}>
        Confirm
      </Button>
    )

    if (canSubmit) return btn

    return <Tooltip label="Resolve issues to continue">{btn}</Tooltip>
  }, [isBasket, handleConfirm, canSubmit])

  const smConfirmButton = useMemo(() => {
    if (!isBasket) return null

    return (
      <Box mt={2} display={[null, 'none']}>
        <Button isDisabled={!canSubmit} onClick={handleConfirm} width="full">
          Confirm
        </Button>
      </Box>
    )
  }, [isBasket, handleConfirm, canSubmit])

  return (
    <Box ref={ref} borderWidth={1} p={[3, 4]} borderRadius="sm" {...rest}>
      {cancelDialog.isOpen && <ConfirmDialog {...cancelDialog} />}
      {confirmDialog.isOpen && <ConfirmDialog {...confirmDialog} />}
      {isMobile && (
        <>
          <DefinitionList>
            <DefinitionTerm m={0}>Title</DefinitionTerm>
            <Definition>{order.title || '—'}</Definition>

            <DefinitionTerm>Purchase Order Number</DefinitionTerm>
            <Definition>{order.poNumber || '—'}</Definition>

            <DefinitionTerm>Delivery Address</DefinitionTerm>
            <Definition>{addressDisplay || '—'}</Definition>

            <DefinitionTerm>Delivery Date</DefinitionTerm>
            <Definition>{deliveryDateDisplay || '—'}</Definition>
          </DefinitionList>

          <Box as="dl" display="grid" gridTemplateColumns="auto auto" mt={4} gridGap={2}>
            <DefinitionTerm m={0}>Order Lines</DefinitionTerm>
            <Definition textAlign="right">{order.items.length}</Definition>

            <DefinitionTerm m={0}>Order Total</DefinitionTerm>
            <Definition textAlign="right">£{order.netTotal}</Definition>
          </Box>
        </>
      )}
      {!isMobile && (
        <>
          <Box display="grid" gridTemplateColumns="1fr 1fr" gridGap={4}>
            <DefinitionList>
              <DefinitionTerm m={0}>Title</DefinitionTerm>
              <Definition>{order.title || '—'}</Definition>

              <DefinitionTerm>Purchase Order Number</DefinitionTerm>
              <Definition>{order.poNumber || '—'}</Definition>
            </DefinitionList>

            <DefinitionList>
              <DefinitionTerm m={0}>Delivery Address</DefinitionTerm>
              <Definition>{addressDisplay || '—'}</Definition>

              <DefinitionTerm>Delivery Date</DefinitionTerm>
              <Definition>{deliveryDateDisplay || '—'}</Definition>
            </DefinitionList>
          </Box>

          <Box display="grid" gridTemplateColumns="1fr 1fr" gridGap={4} mt={4}>
            <DefinitionList m={0}>
              <DefinitionTerm m={0}>Order Lines</DefinitionTerm>
              <Definition>{order.items.length}</Definition>
            </DefinitionList>

            <DefinitionList m={0}>
              <DefinitionTerm m={0}>Order Total</DefinitionTerm>
              <Definition>£{order.netTotal}</Definition>
            </DefinitionList>
          </Box>
        </>
      )}

      {(showCutoff || basketErrorMessage || orderMessage) && (
        <Stack mt={4} borderRadius="sm" bg="gray.100" py={2} px={4} mx={[-3, -4, 0]} spacing={4}>
          {showCutoff && (
            <Text>
              {isBasket ? 'This order must be confirmed before' : 'This order can be edited until'}{' '}
              <Text as="span" fontWeight="semibold">
                {cutoffDisplay}
              </Text>
            </Text>
          )}
          {basketErrorMessage && (
            <Text fontWeight="semibold" color="coral.500">
              {basketErrorMessage}
            </Text>
          )}
          {orderMessage && (
            <Text>
              <Text as="span" fontWeight="semibold">
                {orderMessage}
              </Text>{' '}
              {isBasket && showReciprocalLink && (
                <Link as={RouterLink} to={orderPath(order.order)} color="blue.500">
                  View the confirmed order.
                </Link>
              )}
              {!isBasket && showReciprocalLink && (
                <Link as={RouterLink} to={basketPath(order.basket)} color="blue.500">
                  View the unconfirmed order.
                </Link>
              )}
            </Text>
          )}
        </Stack>
      )}

      <Box mt={4} display="flex" justifyContent="space-between">
        {isBasket && (
          <>
            <Box>
              <Menu autoSelect={false}>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  variant="outline"
                  icon={<OmDotsVertical />}
                  display={['inline-flex', null, 'none']}
                />
                <MenuList>
                  <MenuItem onClick={handleCancel} isDisabled={isDeleting}>
                    Remove
                  </MenuItem>
                </MenuList>
              </Menu>
              <Button
                variant="outline"
                display={['none', null, 'inline-flex']}
                onClick={handleCancel}
                isLoading={isDeleting}>
                Remove
              </Button>
            </Box>
            <Box>
              {canEdit && (
                <Button
                  onClick={onSelect}
                  variant={isSelected ? 'outline' : 'solid'}
                  isLoading={isOpeningForEdit}>
                  Make Changes
                </Button>
              )}
              {mediumUpConfirmButton}
              <Button ml={[3, 4]} as={RouterLink} to={href}>
                View
              </Button>
            </Box>
          </>
        )}

        {!isBasket && (
          <>
            <Box>
              {canCancel && (
                <>
                  <Menu autoSelect={false}>
                    <MenuButton
                      as={IconButton}
                      aria-label="Options"
                      variant="outline"
                      icon={<OmDotsVertical />}
                      display={['inline-flex', null, 'none']}
                    />
                    <MenuList>
                      <MenuItem onClick={handleCancel} isDisabled={isDeleting}>
                        Cancel
                      </MenuItem>
                    </MenuList>
                  </Menu>
                  <Button
                    variant="outline"
                    display={['none', null, 'inline-flex']}
                    onClick={handleCancel}
                    isLoading={isDeleting}>
                    Cancel
                  </Button>
                </>
              )}
            </Box>
            <Box>
              {canEdit && (
                <Button ml={[3, 4]} onClick={onSelect} isLoading={isOpeningForEdit}>
                  Make Changes
                </Button>
              )}
              <BasketFormModalButton
                ml={[3, 4]}
                display={canEdit ? ['none', 'inline-flex'] : 'inline-flex'}
                reorder={order as TOrderWithAddressAndItems}
                onCreated={handleReorderSuccess}
                onSelectExisting={handleSelectExisting}>
                Reorder
              </BasketFormModalButton>
              <Button ml={[3, 4]} as={RouterLink} to={href}>
                View
              </Button>
            </Box>
          </>
        )}
      </Box>
      {smConfirmButton}
    </Box>
  )
})
