import { extendTheme } from '@chakra-ui/react'

import { AllergenIconBadge } from './components/allergen-icon-badge'
import { Accordion } from './components/accordion'
import { Button } from './components/button'
import { Form } from './components/form'
import { IconBadge } from './components/icon-badge'
import { IconLinkBox } from './components/icon-link-box'
import { Nav } from './components/nav'
import { Popover } from './components/popover'
import { SelectMenu } from './components/select-menu'

export const MAX_WIDTH = '80em'

export const theme = extendTheme({
  initialColorMode: 'system',
  colors: {
    // https://colorshadesgenerator.com/#9b9b9b
    // https://noeldelgado.github.io/shadowlord/#8a8c8e
    gray: {
      50: '#f9f9f9', // 95%
      100: '#edeeee', // 85%
      200: '#d0d1d2', // 60%
      300: '#b9babb', // 40%
      400: '#a1a3a5', // 20%
      500: '#8a8c8e', // 0%
      600: '#616263', // 30%
      700: '#454647', // 50%
      800: '#292a2b', // 70%
      900: '#151515', // 85%
    },
    // https://colorshadesgenerator.com/#97bf0f
    green: {
      50: '#fafcf3', // 95%
      100: '#eff5db', // 85%
      200: '#d5e59f', // 60%
      300: '#c1d96f', // 40%
      400: '#accc3f', // 20%
      500: '#97bf0f', // 0%
      600: '#79990c', // 20%
      700: '#627c0a', // 35%
      800: '#536908', // 45%
      900: '#3c4c06', // 60%
    },
    // https://noeldelgado.github.io/shadowlord/#fd5155
    coral: {
      50: '#fff6f7', // 95%
      100: '#ffe5e6', // 85%
      200: '#feb9bb', // 60%
      300: '#fe9799', // 40%
      400: '#fd7477', // 20%
      500: '#fd5155', // 0%
      600: '#ca4144', // 20%
      700: '#b1393b', // 35%
      800: '#8b2d2f', // 45%
      900: '#652022', // 60%
    },
    // https://colorshadesgenerator.com/#003b79
    birchallBlue: {
      50: '#f2f5f8', // 95%
      100: '#d9e2eb', // 85%
      200: '#99b1c9', // 60%
      300: '#6689af', // 40%
      400: '#336294', // 20%
      500: '#003b79', // 0%
      600: '#002f61', // 20%
      700: '#00264f', // 35%
      800: '#002043', // 45%
      900: '#001830', // 60%
    },
  },
  fonts: {
    body: 'Figtree, system-ui, Helvetica, Arial, sans-serif',
    heading: 'AlteDin1451, system-ui, Helvetica, Arial, sans-serif',
  },
  radii: {
    // keep radius to 2px regardless of scale
    none: '0',
    sm: '0.125rem',
    base: '0.125rem',
    md: '0.125rem',
    lg: '0.125rem',
    xl: '0.125rem',
    '2xl': '0.125rem',
    '3xl': '0.125rem',
    full: '9999px',
  },
  styles: {
    global: {
      body: {
        color: 'gray.800',
      },
      'html, body, #root': {
        // height: '100%',
      },
    },
    // global: () => ({
    //   'h1, h2, h3, h4, h5, h6': {
    //     color: 'blue.500',
    //     fontWeight: 400,
    //   }
    // }),
  },
  components: {
    AllergenIconBadge,
    Accordion,
    Button,
    Form,
    IconBadge,
    IconLinkBox,
    Nav,
    Popover,
    SelectMenu,
    Heading: {
      baseStyle: {
        // color: 'birchallBlue.500',
        fontWeight: '400',
      },
    },
    Modal: {
      baseStyle: {
        list: {
          zIndex: 'modal',
        },
      },
    },
  },
})
